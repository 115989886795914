import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import {
  constructSourceSet,
  constructSourceSetSizes,
} from '@utils/common.utils';
import {
  BrowserProps,
  PosterType,
  VideoType,
  VideoPosterType,
} from '../../types';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import { getPosterURL } from '@invideoio/web-shared/utils/common';

interface HeroVideoProps extends BrowserProps {
  video: VideoType;
  poster: VideoPosterType;
}

const HeroVideo = (props: HeroVideoProps) => {
  const { browser, video, poster } = props;
  const desktopPoster: PosterType = poster?.desktop || {
    defaultURL: poster.url,
  };
  const mobilePoster: PosterType = poster?.mobile || { defaultURL: poster.url };

  const desktopImageURL = getPosterURL(desktopPoster, browser);
  const mobileImageURL = getPosterURL(mobilePoster, browser);

  const srcSet = constructSourceSet([
    { url: mobileImageURL, intrinsicWidth: 680 },
    { url: desktopImageURL, intrinsicWidth: 1390 },
  ]);

  const srcSetSizes = constructSourceSetSizes([
    { query: '(min-width: 720px)', size: 'calc(100vw - 50px)' },
    { query: '', size: 'calc(100vw - 40px)' },
  ]);

  return (
    <div className={ivclass('iv-w-full iv-h-full')}>
      {video?.url && (
        <VideoComponent
          autoPlay
          loop
          muted
          width="100%"
          height="100%"
          poster={desktopImageURL}
          loading="preload"
          className="iv-h-full"
        >
          <source src={video?.url} />
        </VideoComponent>
      )}
      {!video?.url && desktopImageURL && (
        <ImageComponent
          elementype="native"
          src={desktopImageURL}
          srcSet={srcSet}
          sizes={srcSetSizes}
          className="iv-h-full iv-object-contain"
          alt="hero"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default HeroVideo;
