import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { BrowserProps, HeroSectionType } from '../../types';
import Title from './Title';
import Details from './Details';
import HeroVideo from './HeroVideo';

interface HeroSectionV2Props extends BrowserProps {
  heroSection: HeroSectionType;
  onCTAIntersection?: Function;
}

const HeroSectionV2 = ({
  browser,
  heroSection,
  onCTAIntersection,
}: HeroSectionV2Props) => {
  const {
    video,
    poster,
    heading,
    sideText,
    buttonText,
    CTAUrl,
    subText,
    showMobileAppCTA,
    MobileAppLink,
  } = heroSection;
  return (
    <div
      id="iv-hero"
      className={ivclass(
        'iv-grid',
        'iv-grid-cols-12 md:iv-grid-cols-8 sm:iv-grid-cols-4',
        'iv-gap-x-40 md:iv-gap-x-25 sm:iv-gap-x-20',
        'iv-mt-100 lg:iv-mt-80 md:iv-mt-75 sm:iv-mt-50 lg:iv-px-40 md:iv-px-25 iv-px-20',
        'xl:iv-ml-10 lg:iv-ml-8',
      )}
    >
      <div
        className={ivclass(
          'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-4',
          'xl:iv-col-start-1 lg:iv-col-start-2 lg:iv-col-end-12 sm:iv-col-start-1',
          'xl:iv-px-15 lg:iv-px-20 iv-w-full',
          'iv-text-center lg:iv-min-h-[60px]',
        )}
      >
        <Title heading={heading} />
      </div>

      <Details
        sideText={sideText}
        buttonText={buttonText}
        CTAUrl={CTAUrl}
        subText={subText}
        onCTAIntersection={onCTAIntersection}
        showMobileAppCTA={Boolean(showMobileAppCTA)}
        MobileAppLink={MobileAppLink}
      />
      {(video || poster) && (
        <div
          className={ivclass(
            'iv-relative',
            'lg:iv-pl-40',
            'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-4',
            'xl:iv-col-start-1 lg:iv-col-start-1',
            'iv-mt-50 md:iv-mt-60 sm:iv-mt-40',
          )}
        >
          <div
            className={ivclass(
              'md:iv-relative sm:iv-relative',
              'iv-h-[650px] md:iv-h-[570px] sm:iv-h-[230px]',
            )}
          >
            <HeroVideo browser={browser} video={video} poster={poster} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSectionV2;
