import React, { useContext, useState, useEffect } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useIntersectionObserver from '@react-hook/intersection-observer';

import IVButton from '@invideoio/web-shared/components/Button';
import { AUTH_TYPE } from '../../constants/homepage';
import { RedirectContext, StateContext } from '../../store/store';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { SideTextType } from 'src/types';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { BaseAppType } from '@constants/common';
import { OSType } from '@invideoio/web-shared/constants/common';
import { MobileAppLink } from '@components/promotion/types';

interface DetailsProps {
  sideText: SideTextType;
  buttonText: string;
  CTAUrl: string;
  subText: string;
  showMobileAppCTA: boolean;
  onCTAIntersection?: Function;
  MobileAppLink?: MobileAppLink;
}

const Details = (props: DetailsProps) => {
  const {
    sideText,
    buttonText,
    CTAUrl,
    subText,
    onCTAIntersection,
    showMobileAppCTA,
    MobileAppLink,
  } = props;
  const { handleClick } = useContext(RedirectContext);
  const { baseAppType, os } = useContext(StateContext);

  const isIOS = os === OSType.iOS;
  const isAndroid = os === OSType.Android;
  const showMobileCTA = showMobileAppCTA && (isIOS || isAndroid);
  const defaultMobileAppURL = 'https://invideo-ai.app.link/hp-hero-cta';

  const {
    AndroidAppCTA,
    AndroidAppLink,
    IOSAppCTA,
    IOSAppLink,
    WebCTAOnMobile,
  } = MobileAppLink || {
    AndroidAppCTA: '',
    AndroidAppLink: '',
    IOSAppCTA: '',
    IOSAppLink: '',
    WebCTAOnMobile: '',
  };

  const webCTAOnMobile = WebCTAOnMobile || 'Try on web';
  const androidAppCTA = AndroidAppCTA || 'Get Android app';
  const iosAppCTA = IOSAppCTA || 'Get iOS app';
  const mobileCTA = isAndroid ? androidAppCTA : iosAppCTA;
  const webCTA = showMobileCTA ? webCTAOnMobile : buttonText;

  const mobileAppURL = isAndroid
    ? AndroidAppLink
    : IOSAppLink || defaultMobileAppURL;

  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Top',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.Top,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    handleClick(CTAUrl, { tab: AUTH_TYPE.SIGNUP });
  };
  const [ctaRef, setCtaRef] = useState<any>(null);
  const [textRef, setTextRef] = useState<any>(null);

  function useIsIntersecting(sectionRef: any, threshold?: number) {
    const { isIntersecting } = useIntersectionObserver(sectionRef, {
      threshold: threshold,
    });
    return isIntersecting;
  }

  const isCtaIntersecting = useIsIntersecting(ctaRef, 1);
  const isTextIntersecting = useIsIntersecting(textRef, 0);

  useEffect(() => {
    if (onCTAIntersection) {
      onCTAIntersection(isTextIntersecting ? true : isCtaIntersecting);
    }
  }, [isCtaIntersecting, isTextIntersecting, onCTAIntersection]);

  return (
    <React.Fragment>
      <div
        className={ivclass(
          'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-4',
          'iv-col-start-3 iv-col-end-11 md:iv-col-start-2 md:iv-col-end-8 sm:iv-col-start-1',
          'iv-pt-50 md:iv-pt-60 sm:iv-pt-40',
        )}
      >
        <div
          className={ivclass(
            'xl:iv-text-heading-3xs lg:iv-text-heading-4xs md:iv-text-heading-4xs sm:iv-text-body-2xl',
            'xl:iv-m-heading-3xs lg:iv-m-heading-4xs md:iv-m-heading-4xs sm:iv-m-body-2xl',
            'iv-font-semibold iv-text-center',
            'xl:iv-px-5',
          )}
        >
          <span
            className={ivclass('iv-text-black-85', 'md:iv-inline sm:iv-inline')}
          >
            {sideText.highlightedText}
          </span>
          <span
            className={ivclass(
              'iv-text-black-50',
              'lg:iv-block md:iv-inline sm:iv-inline',
            )}
          >
            {sideText.plainText1}
          </span>
          {sideText.plainText2 && (
            <span
              className={ivclass(
                'iv-text-black-50',
                'iv-block md:iv-inline sm:iv-inline',
              )}
            >
              {sideText.plainText2}
            </span>
          )}
        </div>
      </div>

      <div
        className={ivclass(
          'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-4',
          'iv-mt-50 md:iv-mt-60 sm:iv-mt-40',
          'iv-text-center',
        )}
      >
        {showMobileCTA && (
          <IVButton
            ref={setCtaRef}
            className={ivclass(
              'iv-flex',
              'iv-justify-center',
              'iv-items-center',
              'iv-cursor-pointer',
              'iv-h-65 sm:iv-h-52',
              'iv-rounded-full',
              'iv-bg-blue hover:iv-bg-blue-strong iv-transition',
              'iv-px-[24px] iv-py-20 sm:iv-px-20',
              'iv-text-grey-0',
              'iv-text-body-3xl sm:iv-text-body-2xl',
              'iv-font-bold sm:iv-font-semibold',
              'iv-mx-auto',
            )}
            type="button"
            onClick={() => {
              window.open(mobileAppURL, '_blank');
            }}
          >
            {mobileCTA}
          </IVButton>
        )}
        {CTAUrl && CTAUrl.startsWith('#') ? (
          <a
            className={ivclass(
              'iv-flex',
              'iv-justify-center',
              'iv-items-center',
              'iv-cursor-pointer',
              'iv-h-65 sm:iv-h-52',
              'iv-rounded-full iv-transition',
              {
                'iv-mt-10 iv-border-solid iv-border-2 iv-border-blue':
                  showMobileCTA,
                'iv-bg-transparent hover:iv-bg-blue iv-text-blue':
                  showMobileCTA,
                'iv-bg-blue hover:iv-bg-blue-strong iv-text-grey-0':
                  !showMobileCTA,
              },
              'iv-px-[24px] iv-py-20 sm:iv-px-20',
              'iv-text-body-3xl sm:iv-text-body-2xl',
              'iv-font-bold sm:iv-font-semibold',
              'iv-mx-auto',
              'iv-w-max',
            )}
            href={CTAUrl}
          >
            {webCTA}
          </a>
        ) : (
          <IVButton
            ref={setCtaRef}
            className={ivclass(
              'iv-flex',
              'iv-justify-center',
              'iv-items-center',
              'iv-cursor-pointer',
              'iv-h-65 sm:iv-h-52',
              'iv-rounded-full iv-transition',
              {
                'iv-mt-10 iv-border-solid iv-border-2 iv-border-blue':
                  showMobileCTA,
                'iv-bg-transparent hover:iv-bg-blue iv-text-blue':
                  showMobileCTA,
                'iv-bg-blue hover:iv-bg-blue-strong iv-text-grey-0':
                  !showMobileCTA,
              },
              'iv-px-[24px] iv-py-20 sm:iv-px-20',
              'iv-text-body-3xl sm:iv-text-body-2xl',
              'iv-font-bold sm:iv-font-semibold',
              'iv-mx-auto',
            )}
            type="button"
            onClick={onClick}
          >
            {webCTA}
          </IVButton>
        )}

        <div
          className={ivclass(
            'iv-mt-[16px] sm:iv-mt-10 md:iv-pb-0 sm:iv-pb-0',
            'md:iv-mx-auto sm:iv-mx-auto',
            'iv-text-grey-50',
            'iv-text-body-m sm:iv-text-body-xs',
            'iv-font-medium',
          )}
        >
          {subText}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Details;
